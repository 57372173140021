import React, { useEffect, useState } from 'react';
import './Weddings.css'; // Make sure the CSS file is imported
import GalleryImage from './component/GalleryImage';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const Weddings = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch('https://backend.dazzzlingsphere.com/api/gallery?category=corporateevent')
      .then(response => response.json())
      .then(data => {
        setImages(data); 
      })
      .catch(error => console.error('Error fetching images:', error));
  }, []);

  return (
    <div className="weddings-gallery">
      <h1>Captured Moments: A Corporate Event Gallery</h1>
      <div className='row row-gal'>
        {images.map((image, index) => (
          <GalleryImage key={index} src={image.src} />
        ))}
      </div>
    </div>
  );
};

export default Weddings;
