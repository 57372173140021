import React from 'react';
import '../Weddings.css';

const GalleryItem = ({ src, alt }) => {
    const isVideo = (src) => {

        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        return videoExtensions.some(extension => src.endsWith(extension));
    };

    return (
        <div className="col-4" style={{ width: 'auto' }}>
            <figure>
                {isVideo(src) ? (
                    <video src={src} alt={alt} className='imagegal' controls></video>
                ) : (
                    <img src={src} alt={alt} className='imagegal' />
                )}
            </figure>
        </div>
    );
};

export default GalleryItem;