import React, { useEffect, useState } from 'react';
import './Weddings.css';
import GalleryImage from './component/GalleryImage';

const Weddings = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Debugging URL
        console.log('Fetching images from:', 'http://62.72.29.78:3004/api/gallery?category=birthdays');

        fetch('http://62.72.29.78:3004/api/gallery?category=birthdays', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); // Enhanced error handling
                }
                return response.json();
            })
            .then(data => {
                setImages(data);
            })
            .catch(error => {
                console.error('Error fetching images:', error); // More detailed error logging
                alert('Failed to fetch images. Check the console for more details.'); // Alert for visible feedback
            });
    }, []);

    return (
        <div className="weddings-gallery">
            <h1>Captured Moments: A Birthday Gallery</h1>
            <div className='row'>
                {images.map((image, index) => (
                    <GalleryImage key={index} src={image.src} />
                ))}
            </div>
        </div>
    );
};

export default Weddings;
