import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header'; // Assuming you have a Header component
import Home from './Home'; // Import the Home component
import Weddings from './Weddings';
import Birthdays from './Birthdays';
import BabyShower from './Babys';
import CollegeFest from './College';
import Corporate from './Corporate';
import School from './School';
import Test from './Test2';

function App() {
  return (
    <Router>
      <div>
        <Header />
       
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/weddings" element={<Weddings />}/>
        <Route path="/birthdays" element={<Birthdays />}/>
        <Route path="/babyshower" element={<BabyShower />}/>
        <Route path="/collegefest" element={<CollegeFest />}/>
        <Route path="/corporateevent" element={<Corporate />}/>
          <Route path="/schoolevent" element={<School />} />
          <Route path="/api" element={<galleryApi />} />
          <Route path="/test" element={<Test />} />
        </Routes>
   
      </div>
    </Router>
  );
}

export default App;
